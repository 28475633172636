._{
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}
.header{
    color: #cccccc;
    font-size: 40pt;
    width: 100%;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.text{
    font-size: 15pt;
    position: absolute;
    color: #fff;
    z-index: 5;
    top: 60px;
    text-align: center;
    width: 100%;
    text-shadow: 4px 5px 6px #000;
}
.text2{
    top: 50px;
}
.count{
    font-size: 30px;
    margin-top: 10px;
    position: absolute;
    color: #fff;
    z-index: 5;
    top: 100px;
    text-align: center;
    width: 100%;
    text-shadow: 4px 5px 6px #000;
}
.globe-container,
.globe {
    width: 200px;
    height: 200px;
}
.globe-container {
    position: relative;
    display: inline-block;
    margin: 30px;
    transform: scale(1.1);
}
.globe {
    position: relative;
    display: block;
    margin: 10px;
    padding: 0;
    top: 0;
    left: 0;
}
.globe_worldmap,
.globe_worldmap_front,
.globe_worldmap_back,
.globe_sphere,
.globe_outer_shadow,
.globe_inner_shadow {
    position: absolute;
    display: block;
    margin: 0;
}
.globe_sphere,
.globe_outer_shadow,
.globe_inner_shadow {
    left: 0;
    top: 0;
    width: 200px;
    height: 200px;
    background-position: 0 0;
    background-repeat: no-repeat;
}
.globe_worldmap {
    left: 0;
    top: 0;
    width: 200px;
    height: 200px;
    overflow: hidden;
    border-radius: 50%;
}
.globe_worldmap_front,
.globe_worldmap_back {
    left: 0;
    top: 0;
    width: 1000px;
    height: 200px;
    overflow: visible;
    background-image: url('../../images/svg/worldmap-4.svg');
    background-repeat: no-repeat;
}
.globe-outer-shadow {
    left: 0;
    top: 186px;
    width: 200px;
    height: 30px;
}
.globe_inner_shadow {
    background-image: url('../../images/svg/inner_shadow.svg');
}

.globe_worldmap_front {
    background-position: 0px 0px;
    animation: textureSpinreverse 8s linear infinite;
}
.globe_worldmap_back {
    background-position: 0px -220px;
    animation: textureSpin 8s linear infinite;
}

@keyframes textureSpin {
    from {
        transform: translateX(0);
    }
    to {
        transform: translateX(-47.5%);
    }
}
@keyframes textureSpinreverse {
    from {
        transform: translateX(-47.5%);
    }
    to {
        transform: translateX(0);
    }
}