html,
body,
#root,
.App {
    padding: 0;
    margin: 0;
    width: 100%;
    height: 100%;
    background: #222222;
    font: 12px Helvetica Neue,Arial,Helvetica,sans-serif;
}

* {
    box-sizing: border-box;
}
.mapboxgl-control-container{
    display: none;
}