._{
    width: 100%;
    height: 50%;
    background: #222222;
}
.info_block{
    color: #cccccc;
    padding: 20px;
    z-index: 1;
    position: absolute;
}
.info_block_block{
    display: flex;
    align-items: center;
}
.server_icon{
    height: 10px;
    width: 10px;
    background: #ff00ff;
    border-radius: 100%;
    margin-right: 9px;
    margin-left: 5px;
    border: 2px solid #ffffff;
}
.location_icon {
    width: 20px;
    height: 20px;
    margin: 0px 5px 10px 0;
    background: red;
    border-radius: 0 100% 100% 100%;
    transform: rotateZ(-135deg);
}
.location_icon_center{
    background: white;
    border-radius: 100%;
    height: 8px;
    width: 8px;
    margin: 6px 0 0 6px;
}