._{
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 10px;
    margin: 20px;
    padding-bottom: 10px;
    color: #cccccc;
}
.link{
    color: #cccccc;
}